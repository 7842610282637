<template>
  <LayoutMain
    v-if="$route.meta.layout == 'main' && $store.state.user != null"
  />
  <LayoutScan
    v-if="$route.meta.layout == 'scan' && $store.state.user != null"
  />
  <LayoutAuth v-if="$route.meta.layout == 'auth'" />
</template>

<script>
import LayoutMain from "./Layouts/LayoutMain.vue";
import LayoutScan from "./Layouts/LayoutScan.vue";
import LayoutAuth from "./Layouts/LayoutAuth.vue";

export default {
  name: "App",
  components: {
    LayoutAuth,
    LayoutScan,
    LayoutMain,
  },
  mounted() {
    setTimeout(() => {
      this.$axios.get(`/setting`).then((res) => {
        if (res) {
          //meta
          document
            .getElementsByTagName("meta")
            .namedItem("keywords")
            .setAttribute("content", res.data.setting.app_name);
          document
            .getElementsByTagName("meta")
            .namedItem("description")
            .setAttribute("content", res.data.setting.description);

          document.title = res.data.setting.app_name;

          var link = document.querySelector("link[rel~='icon']");
          if (!link) {
            link = document.createElement("link");
            link.rel = "icon";
            document.head.appendChild(link);
          }
          link.href = res.data.setting.app_logo_url;

          //state
          this.$store.commit("updateSetting", res.data.setting);
        }
      });
    }, 1000);
  },
};
</script>
