<template>
  <!-- <div class="buy-now-wrapper">
    <a
      v-if="$store.state.setting?.phone_number != null"
      :href="'https://wa.me/' + $store.state.setting?.phone_number"
      target="_blank"
      class="btn btn-success btn-icon"
      style="border-radius: 50%"
    >
      <i class="ri-whatsapp-line"></i>
    </a>
  </div> -->
  <div class="main-wrapper">
    <!-- partial:partials/_sidebar.html -->
    <nav class="sidebar">
      <div class="sidebar-header">
        <router-link to="/" class="sidebar-brand">
          <img
            :src="$store.state.setting?.app_logo_url"
            style="height:30px; width; :auto "
            alt=""
          />
        </router-link>
        <div class="sidebar-toggler not-active" @click="toggleSidebar()">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div
        class="sidebar-body"
        style="overflow-y: scroll"
        v-if="
          $store.state.user.role == 'admin' ||
          $store.state.user.role == 'operator' ||
          ($store.state.user.role == 'member' &&
            $store.state.user.member.is_password_reset == 1)
        "
      >
        <ul class="nav">
          <li class="nav-item nav-category">Main</li>
          <li
            class="nav-item"
            :class="{
              active: $route.meta.menu == 'dashboard',
            }"
          >
            <router-link to="/dashboard" class="nav-link">
              <i class="ri-home-line"></i>
              <span class="link-title">Dashboard</span>
            </router-link>
          </li>

          <li
            class="nav-item nav-category"
            v-if="
              $store.state.user.role == 'admin' ||
              $store.state.user.role == 'operator'
            "
          >
            Managemen Pengguna
          </li>
          <template v-if="$store.state.user.role == 'admin'">
            <li
              class="nav-item"
              :class="{
                active: $route.meta.menu == 'operator',
              }"
            >
              <router-link to="/operator" class="nav-link">
                <i class="ri-shield-user-line"></i>
                <span class="link-title">Operator </span>
              </router-link>
            </li>
          </template>
          <template v-if="$store.state.user.role != 'member'">
            <li
              class="nav-item"
              :class="{
                active: menu.member.indexOf($route.meta.menu) > -1,
              }"
            >
              <a
                class="nav-link"
                data-bs-toggle="collapse"
                href="#member"
                role="button"
                :aria-expanded="menu.member.indexOf($route.meta.menu) > -1"
                aria-controls="member"
              >
                <i class="ri-user-2-line"></i>
                <span class="link-title">Member</span>
                <i class="link-arrow"> <i class="ri-arrow-down-s-line"></i></i>
              </a>
              <div
                class="collapse"
                :class="{
                  show: menu.member.indexOf($route.meta.menu) > -1,
                }"
                id="member"
              >
                <ul class="nav sub-menu">
                  <li class="nav-item">
                    <router-link
                      :to="'/member?type=student'"
                      class="nav-link"
                      :class="{
                        active:
                          $route.meta.menu == 'member' &&
                          $route.query.type == 'student',
                      }"
                      >List Siswa</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link
                      :to="'/member?type=teacher'"
                      class="nav-link"
                      :class="{
                        active:
                          $route.meta.menu == 'member' &&
                          $route.query.type == 'teacher',
                      }"
                      >List Guru</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link
                      :to="'/major'"
                      class="nav-link"
                      :class="{
                        active: $route.meta.menu == 'major',
                      }"
                      >Jurusan</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link
                      :to="'/study_group'"
                      class="nav-link"
                      :class="{
                        active: $route.meta.menu == 'study_group',
                      }"
                      >Kelas</router-link
                    >
                  </li>
                </ul>
              </div>
            </li>

            <li class="nav-item nav-category">Master</li>
            <li
              class="nav-item"
              :class="{
                active: menu.book.indexOf($route.meta.menu) > -1,
              }"
            >
              <a
                class="nav-link"
                data-bs-toggle="collapse"
                href="#book"
                role="button"
                :aria-expanded="menu.book.indexOf($route.meta.menu) > -1"
                aria-controls="book"
              >
                <i class="ri-booklet-line"></i>
                <span class="link-title">Buku</span>
                <i class="link-arrow"> <i class="ri-arrow-down-s-line"></i></i>
              </a>
              <div
                class="collapse"
                :class="{
                  show: menu.book.indexOf($route.meta.menu) > -1,
                }"
                id="book"
              >
                <ul class="nav sub-menu">
                  <li class="nav-item">
                    <router-link
                      :to="'/book'"
                      class="nav-link"
                      :class="{
                        active: $route.meta.menu == 'book',
                      }"
                      >List Buku</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link
                      :to="'/book/writer'"
                      :class="{
                        active: $route.meta.menu == 'writer',
                      }"
                      class="nav-link"
                      >Penulis</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link
                      :to="'/book/publisher'"
                      :class="{
                        active: $route.meta.menu == 'publisher',
                      }"
                      class="nav-link"
                      >Penerbit</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link
                      :to="'/book/shelf'"
                      :class="{
                        active: $route.meta.menu == 'shelf',
                      }"
                      class="nav-link"
                      >Rak Penyimpanan</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link
                      :to="'/book/category'"
                      :class="{
                        active: $route.meta.menu == 'category',
                      }"
                      class="nav-link"
                      >Kategori</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link
                      :to="'/book/tag'"
                      :class="{
                        active: $route.meta.menu == 'tag',
                      }"
                      class="nav-link"
                      >Tag</router-link
                    >
                  </li>
                </ul>
              </div>
            </li>

            <li
              class="nav-item"
              :class="{
                active: menu.video.indexOf($route.meta.menu) > -1,
              }"
            >
              <a
                class="nav-link"
                data-bs-toggle="collapse"
                href="#video"
                role="button"
                :aria-expanded="menu.video.indexOf($route.meta.menu) > -1"
                aria-controls="book"
              >
                <i class="ri-video-line"></i>
                <span class="link-title">Video Pembelajaran</span>
                <i class="link-arrow"> <i class="ri-arrow-down-s-line"></i></i>
              </a>
              <div
                class="collapse"
                :class="{
                  show: menu.video.indexOf($route.meta.menu) > -1,
                }"
                id="video"
              >
                <ul class="nav sub-menu">
                  <li class="nav-item">
                    <router-link
                      :to="'/video'"
                      class="nav-link"
                      :class="{
                        active: $route.meta.menu == 'video',
                      }"
                      >List Video</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link
                      :to="'/video/video_channel'"
                      class="nav-link"
                      :class="{
                        active: $route.meta.menu == 'video_channel',
                      }"
                      >Instruktur</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link
                      :to="'/video/category'"
                      class="nav-link"
                      :class="{
                        active: $route.meta.menu == 'video_category',
                      }"
                      >Kategori Video</router-link
                    >
                  </li>
                </ul>
              </div>
            </li>
            <li
              class="nav-item"
              :class="{
                active: menu.audio.indexOf($route.meta.menu) > -1,
              }"
            >
              <a
                class="nav-link"
                data-bs-toggle="collapse"
                href="#audio"
                role="button"
                :aria-expanded="menu.audio.indexOf($route.meta.menu) > -1"
              >
                <i class="ri-music-line"></i>
                <span class="link-title">Audio Book</span>
                <i class="link-arrow"> <i class="ri-arrow-down-s-line"></i></i>
              </a>
              <div
                class="collapse"
                :class="{
                  show: menu.audio.indexOf($route.meta.menu) > -1,
                }"
                id="audio"
              >
                <ul class="nav sub-menu">
                  <li class="nav-item">
                    <router-link
                      :to="'/audio'"
                      class="nav-link"
                      :class="{
                        active: $route.meta.menu == 'audio',
                      }"
                      >List audio</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link
                      :to="'/audio/audio_channel'"
                      class="nav-link"
                      :class="{
                        active: $route.meta.menu == 'audio_channel',
                      }"
                      >Instruktur</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link
                      :to="'/audio/category'"
                      class="nav-link"
                      :class="{
                        active: $route.meta.menu == 'audio_category',
                      }"
                      >Kategori Audio</router-link
                    >
                  </li>
                </ul>
              </div>
            </li>
          </template>

          <template v-else>
            <li class="nav-item nav-category">E-Perpustakaan</li>
            <li
              class="nav-item"
              :class="{
                active: $route.meta.menu == 'book',
              }"
            >
              <router-link :to="'/book'" class="nav-link">
                <i class="ri-booklet-line"></i>

                <span class="link-title">Buku Perpustakaan</span>
              </router-link>
            </li>

            <li
              class="nav-item"
              :class="{
                active: $route.meta.menu == 'video',
              }"
            >
              <router-link :to="'/video'" class="nav-link">
                <i class="ri-video-line"></i>
                <span class="link-title">Video Pembelajaran</span>
              </router-link>
            </li>

            <li
              class="nav-item"
              :class="{
                active: $route.meta.menu == 'audio',
              }"
            >
              <router-link :to="'/audio'" class="nav-link">
                <i class="ri-music-line"></i>
                <span class="link-title">Audio Book</span>
              </router-link>
            </li>
          </template>

          <li class="nav-item nav-category">E-Service</li>

          <li
            class="nav-item"
            :class="{
              active: $route.meta.menu == 'guest_book',
            }"
            v-if="$store.state.user.role != 'member'"
          >
            <router-link to="/guest_book" class="nav-link">
              <i class="ri-git-repository-line"></i>
              <span class="link-title">Buku Tamu </span>
            </router-link>
          </li>
          <li
            class="nav-item"
            :class="{
              active: $route.meta.menu == 'reserve',
            }"
          >
            <router-link to="/book/reserve" class="nav-link">
              <i class="ri-book-line"></i>
              <span class="link-title">Peminjaman Buku</span>
            </router-link>
          </li>
          <li
            class="nav-item"
            :class="{
              active: $route.meta.menu == 'reserveHistory',
            }"
          >
            <router-link to="/book/reserve-history" class="nav-link">
              <i class="ri-book-read-line"></i>
              <span class="link-title">Riwayat Peminjaman</span>
            </router-link>
          </li>

          <template v-if="$store.state.user.role == 'admin'">
            <li class="nav-item nav-category">Laporan</li>
            <li
              class="nav-item"
              :class="{
                active: $route.meta.menu == 'report-book',
              }"
            >
              <router-link to="/report/book" class="nav-link">
                <i class="ri-book-2-line"></i>
                <span class="link-title">Buku</span>
              </router-link>
            </li>

            <li
              class="nav-item"
              :class="{
                active: $route.meta.menu == 'report-guest-book',
              }"
            >
              <router-link to="/report/guest_book" class="nav-link">
                <i class="ri-git-repository-line"></i>
                <span class="link-title">Buku Tamu</span>
              </router-link>
            </li>

            <li
              class="nav-item"
              :class="{
                active: $route.meta.menu == 'report-reserve-book',
              }"
            >
              <router-link to="/report/reserve_book" class="nav-link">
                <i class="ri-book-mark-line"></i>
                <span class="link-title">Laporan Peminjaman</span>
              </router-link>
            </li>

            <li
              class="nav-item"
              :class="{
                active: $route.meta.menu == 'report-finance',
              }"
            >
              <router-link to="/report/finance" class="nav-link">
                <i class="ri-money-dollar-circle-line"></i>
                <span class="link-title">Laporan Keuangan</span>
              </router-link>
            </li>

            <li class="nav-item nav-category">Pengaturan Umum</li>
            <li
              class="nav-item"
              :class="{
                active: $route.meta.menu == 'setting',
              }"
            >
              <router-link to="/setting" class="nav-link">
                <i class="ri-settings-line"></i>
                <span class="link-title">Pengaturan Aplikasi</span>
              </router-link>
            </li>
            <li
              class="nav-item"
              :class="{
                active: $route.meta.menu == 'variable',
              }"
            >
              <router-link to="/variable" class="nav-link">
                <i class="ri-rocket-2-line"></i>
                <span class="link-title">Variable Umum</span>
              </router-link>
            </li>
          </template>
        </ul>
      </div>
      <div class="sidebar-body" v-else>
        <ul class="nav">
          <li
            class="nav-item"
            :class="{
              active: $route.meta.menu == 'change_password',
            }"
          >
            <router-link to="/change-password" class="nav-link">
              <i class="ri-user-settings-line"></i>
              <span class="link-title">Ubah Password</span>
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
    <!-- partial -->

    <div class="page-wrapper">
      <!-- partial:partials/_navbar.html -->
      <nav class="navbar">
        <a href="#" class="sidebar-toggler" @click="toggleSidebar()">
          <i class="ri-menu-line"></i>
        </a>
        <div class="navbar-content">
          <form
            class="search-form"
            method="post"
            @submit.prevent="search()"
            v-if="$store.state.user.role == 'member'"
          >
            <div class="input-group">
              <div class="input-group-text">
                <i class="ri-search-eye-line"></i>
              </div>
              <input
                type="text"
                v-model="keyword"
                class="form-control"
                id="navbarForm"
                placeholder="Cari buku/video/audio disini..."
              />
            </div>
          </form>
          <ul class="navbar-nav">
            <!-- <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="notificationDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="ri-notification-line"></i>
                <div class="indicator">
                  <div class="circle"></div>
                </div>
              </a>
              <div
                class="dropdown-menu p-0"
                aria-labelledby="notificationDropdown"
              >
                <div
                  class="px-3 py-2 d-flex align-items-center justify-content-between border-bottom"
                >
                  <p>6 New Notifications</p>
                  <a href="javascript:;" class="text-muted">Clear all</a>
                </div>
                <div class="p-1">
                  <a
                    href="javascript:;"
                    class="dropdown-item d-flex align-items-center py-2"
                  >
                    <div
                      class="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3"
                    >
                      <i class="icon-sm text-white" data-feather="gift"></i>
                    </div>
                    <div class="flex-grow-1 me-2">
                      <p>New Order Recieved</p>
                      <p class="tx-12 text-muted">30 min ago</p>
                    </div>
                  </a>
                  <a
                    href="javascript:;"
                    class="dropdown-item d-flex align-items-center py-2"
                  >
                    <div
                      class="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3"
                    >
                      <i
                        class="icon-sm text-white"
                        data-feather="alert-circle"
                      ></i>
                    </div>
                    <div class="flex-grow-1 me-2">
                      <p>Server Limit Reached!</p>
                      <p class="tx-12 text-muted">1 hrs ago</p>
                    </div>
                  </a>
                  <a
                    href="javascript:;"
                    class="dropdown-item d-flex align-items-center py-2"
                  >
                    <div
                      class="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3"
                    >
                      <img
                        class="wd-30 ht-30 rounded-circle"
                        src="/assets/images/faces/face6.jpg"
                        alt="userr"
                      />
                    </div>
                    <div class="flex-grow-1 me-2">
                      <p>New customer registered</p>
                      <p class="tx-12 text-muted">2 sec ago</p>
                    </div>
                  </a>
                  <a
                    href="javascript:;"
                    class="dropdown-item d-flex align-items-center py-2"
                  >
                    <div
                      class="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3"
                    >
                      <i class="icon-sm text-white" data-feather="layers"></i>
                    </div>
                    <div class="flex-grow-1 me-2">
                      <p>Apps are ready for update</p>
                      <p class="tx-12 text-muted">5 hrs ago</p>
                    </div>
                  </a>
                  <a
                    href="javascript:;"
                    class="dropdown-item d-flex align-items-center py-2"
                  >
                    <div
                      class="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3"
                    >
                      <i class="icon-sm text-white" data-feather="download"></i>
                    </div>
                    <div class="flex-grow-1 me-2">
                      <p>Download completed</p>
                      <p class="tx-12 text-muted">6 hrs ago</p>
                    </div>
                  </a>
                </div>
                <div
                  class="px-3 py-2 d-flex align-items-center justify-content-center border-top"
                >
                  <a href="javascript:;">View all</a>
                </div>
              </div>
            </li> -->
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="profileDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  class="wd-30 ht-30 rounded-circle"
                  :src="
                    $store.state.user.profile_picture_url != null
                      ? $store.state.user.profile_picture_url
                      : '/assets/images/user.png'
                  "
                  alt="profile"
                  onerror="this.src=`/assets/images/user.png`"
                />
              </a>
              <div class="dropdown-menu p-0" aria-labelledby="profileDropdown">
                <div
                  class="d-flex flex-column align-items-center border-bottom px-5 py-3"
                >
                  <div class="mb-3">
                    <img
                      class="wd-80 ht-80 rounded-circle"
                      :src="
                        $store.state.user.profile_picture_url != null
                          ? $store.state.user.profile_picture_url
                          : '/assets/images/user.png'
                      "
                      alt=""
                      onerror="this.src=`/assets/images/user.png`"
                    />
                  </div>
                  <div class="text-center">
                    <p class="tx-16 fw-bolder">{{ $store.state.user.name }}</p>
                    <p class="tx-12 text-muted">
                      {{ $store.state.user.email }}
                    </p>
                  </div>
                </div>
                <ul class="list-unstyled p-1">
                  <li
                    class="dropdown-item py-2"
                    data-bs-toggle="modal"
                    data-bs-target="#modalProfile"
                  >
                    <a href="javasript:void(0)" class="text-body ms-0">
                      <i class="me-2 icon-md" data-feather="user"></i>
                      <span>Profile</span>
                    </a>
                  </li>

                  <li class="dropdown-item py-2" @click="logout()">
                    <a href="javascript:void(0);" class="text-body ms-0">
                      <i class="me-2 icon-md" data-feather="log-out"></i>
                      <span>Log Out</span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </nav>
      <!-- partial -->

      <!-- Modal -->
      <div
        class="modal fade"
        id="modalProfile"
        tabindex="-1"
        aria-labelledby="modalProfileLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalProfileLabel">
                Profile Pengguna
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="btn-close"
                ref="btnClose"
              ></button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="updateProfile()">
                <div class="mb-3">
                  <div class="row">
                    <div class="mb-3 col-6">
                      <label class="form-label" for="formFile"
                        >Foto Profil</label
                      >
                      <br />
                      <img
                        v-if="current_photo_profile || form.profile_picture_url"
                        class="img-fluid wid-150"
                        :src="
                          current_photo_profile != null
                            ? current_photo_profile
                            : form.profile_picture_url
                        "
                        alt="User Profile"
                        style="width: 50%"
                        onerror="this.src=`/assets/images/user.png`"
                      />
                      <input
                        class="form-control mt-2"
                        type="file"
                        accept="image/*"
                        id="upload"
                        ref="file"
                        @change="handleFileUpload()"
                      />
                      <small class="text-muted"
                        >* Masukan gambar dengan resolusi 250x250 untuk foto
                        profile</small
                      >
                    </div>
                    <div class="col-12 mb-3">
                      <label for="name" class="form-label">Nama </label>

                      <input
                        type="text"
                        class="form-control"
                        id="name"
                        v-model="form.name"
                        placeholder="Masukan nama aplikasi"
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label for="username" class="form-label">Username</label>

                      <input
                        type="text"
                        class="form-control"
                        id="username"
                        v-model="form.username"
                        placeholder="Masukan Whatstapp"
                      />
                    </div>
                    <div class="col-6 mb-3">
                      <label for="email" class="form-label">Email</label>

                      <input
                        type="email"
                        class="form-control"
                        id="email"
                        v-model="form.email"
                        placeholder="Masukan email"
                      />
                    </div>

                    <div class="col-6 mb-3">
                      <label for="password" class="form-label">Password</label>

                      <input
                        type="password"
                        class="form-control"
                        id="password"
                        v-model="form.password"
                        placeholder="Masukan pasword"
                      />
                      <small class="text-muted">
                        * Kosongkan jika tidak ingin mengubah mengubah
                        password</small
                      >
                    </div>

                    <div class="col-6 mb-3">
                      <label for="password_confirmation" class="form-label"
                        >Konfirmasi Password</label
                      >

                      <input
                        type="password"
                        class="form-control"
                        v-model="form.password_confirmation"
                        placeholder="Masukan konfirmasi password"
                      />
                    </div>

                    <div class="col-12">
                      <button
                        class="btn btn-primary btn-sm float-end"
                        type="submit"
                      >
                        Simpan
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="page-content">
        <router-view :key="$route.fullPath" />
      </div>

      <!-- partial:partials/_footer.html -->
      <footer
        class="footer d-flex flex-column flex-md-row align-items-center justify-content-between px-4 py-3 border-top small"
      >
        <p class="text-muted mb-1 mb-md-0">
          Copyright © 2023
          <a :href="$store.state.setting?.url_address" target="_blank">{{
            $store.state.setting?.app_name
          }}</a
          >.
        </p>
        <!-- <p class="text-muted">by DAC</p> -->
      </footer>
      <!-- partial -->
    </div>
  </div>
</template>
<script>
export default {
  name: "MainLayout",
  data: () => ({
    navbarToggled: false,
    menu: {
      book: ["book", "tag", "category", "writer", "publisher", "shelf"],
      member: ["member", "teacher", "major", "study_group"],
      audio: ["audio", "audio_category", "audio_channel"],
      video: ["video", "video_category", "video_channel"],
    },
    form: {
      profile_picture: null,
      name: null,
      username: null,
      email: null,
      password: null,
      password_confirmation: null,
    },
    keyword: null,
    current_photo_profile: null,
  }),
  mounted() {
    this.form = this.$store.state.user;
    this.$refs.btnClose.click();
  },
  methods: {
    updateProfile() {
      this.$axios
        .post("/profile", this.form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res) {
            this.$store.commit("updateUser", res.data.user);
            this.$toast.info(res.data.message);
            this.form.password = null;
            this.form.password_confirmation = null;
          }
        });
    },

    logout() {
      this.$swal({
        title: "Anda Akan Keluar Dari Aplikasi",
        text: "Lanjut?",
        icon: "warning",
        showCancelButton: true,
        buttons: true,
        dangerMode: true,
      }).then((value) => {
        if (value.isConfirmed) {
          this.$axios.post("/logout").then(() => {
            this.$store.commit("updateUser", null);
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            delete this.$axios.defaults.headers.Authorization;
            this.$router.push("/");
          });
        }
        return;
      });
    },

    search() {
      if (this.keyword != null) {
        let keyword = this.keyword;
        this.keyword = null;
        this.$router.push("/dashboard?keyword=" + keyword);
      }
    },

    toggleSidebar() {
      const body = document.body;
      body.classList.toggle("sidebar-open");
    },
    handleFileUpload() {
      this.form.profile_picture = this.$refs.file.files[0];
      this.current_photo_profile = URL.createObjectURL(
        this.$refs.file.files[0]
      );
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
</style>
