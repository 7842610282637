import { createApp } from "vue";

import router from "./router";

import $axios from "./api";
import App from "./App.vue";
import Toaster from "@meforma/vue-toaster";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import Skeleton from "vue-loading-skeleton";
import "vue-loading-skeleton/dist/style.css";
import store from "./store/index";

import VueMobileDetection from "vue-mobile-detection";

import "remixicon/fonts/remixicon.css";

const app = createApp(App)
  .use(store)
  .use(Toaster, {
    position: "top-right",
    dismissible: true,
    pauseOnHover: true,
  })
  .use(VueSweetalert2)
  .use(VueMobileDetection)
  .use(router);

app.config.globalProperties.$axios = { ...$axios };

app.use(Skeleton).mount("#app");
