import { createRouter, createWebHistory } from "vue-router";
import store from "./store/index";

const routes = [
  {
    path: "/",
    name: "Login Page",
    component: () => import("@/views/LoginPage"),
    meta: {
      layout: "auth",
    },
  },
  {
    path: "/dashboard",
    name: "Halaman Dashboard",
    component: () => import("@/views/Dashboard/Index"),
    meta: {
      layout: "main",
      menu: "dashboard",
    },
  },
  {
    path: "/operator",
    name: "Operator Page",
    meta: {
      layout: "main",
      menu: "operator",
    },
    children: [
      {
        path: "",
        name: "List Operator",
        component: () => import("@/views/Operator/Index"),
        meta: {},
      },
      {
        path: "create",
        name: "Tambah Operator",
        component: () => import("@/views/Operator/Create"),
        meta: {},
      },
      {
        path: ":user_id/edit",
        name: "Edit Operator",
        component: () => import("@/views/Operator/Edit"),
        meta: {},
      },
    ],
  },
  {
    path: "/member",
    name: "Halaman Siswa",
    meta: {
      layout: "main",
      menu: "member",
    },
    children: [
      {
        path: "",
        name: "List Siswa",
        component: () => import("@/views/Member/Index"),
        meta: {},
      },
      {
        path: "create",
        name: "Tambah Siswa",
        component: () => import("@/views/Member/Create"),
        meta: {},
      },
      {
        path: ":member_id/edit",
        name: "Edit Member",
        component: () => import("@/views/Member/Edit"),
        meta: {},
      },
    ],
  },
  {
    path: "/major",
    name: "List Jurusan",
    component: () => import("@/views/Member/Major/Index"),
    meta: {
      layout: "main",
      menu: "major",
    },
  },
  {
    path: "/study_group",
    name: "List Kelas",
    component: () => import("@/views/Member/StudyGroup/Index"),
    meta: {
      layout: "main",
      menu: "study_group",
    },
  },
  {
    path: "/book",
    name: "Book Page",
    meta: {
      layout: "main",
      menu: "book",
    },
    children: [
      {
        path: "",
        name: "List Buku",
        component: () => import("@/views/Book/Index"),
        meta: {
          menu: "book",
        },
      },
      {
        path: ":book_id",
        name: "Detail Buku",
        component: () => import("@/views/Book/Detail"),
        meta: {
          menu: "book",
        },
      },
      {
        path: "create",
        name: "Tambah Buku",
        component: () => import("@/views/Book/Create"),
        meta: {},
      },
      {
        path: ":book_id/edit",
        name: "Edit Buku",
        component: () => import("@/views/Book/Edit"),
        meta: {},
      },
      {
        path: ":book_id/copy",
        name: "Eksemplar Buku",
        component: () => import("@/views/Book/Copy"),
        meta: {},
      },
      {
        path: ":book_id/print",
        name: "Eksemplar Buku Print",
        component: () => import("@/views/Book/Print"),
        meta: {
          layout: "auth",
        },
      },
      {
        path: "writer",
        name: "Penulis Buku",
        component: () => import("@/views/Book/Writer/Index"),
        meta: {
          menu: "writer",
        },
      },
      {
        path: "publisher",
        name: "Penerbit Buku",
        component: () => import("@/views/Book/Publisher/Index"),
        meta: {
          menu: "publisher",
        },
      },
      {
        path: "shelf",
        name: "Rak Buku",
        component: () => import("@/views/Book/Shelf/Index"),
        meta: {
          menu: "shelf",
        },
      },
      {
        path: "tag",
        name: "Tag Buku",
        component: () => import("@/views/Book/Tag/Index"),
        meta: {
          menu: "tag",
        },
      },
      {
        path: "category",
        name: "Kategori Buku",
        component: () => import("@/views/Book/Category/Index"),
        meta: {
          menu: "category",
        },
      },
      {
        path: "reserve",
        name: "Peminjaman Buku",
        component: () => import("@/views/Book/Reserve/Index"),
        meta: {
          menu: "reserve",
        },
      },
      {
        path: "reserve-history",
        name: "History Peminjaman Buku",
        component: () => import("@/views/Book/ReserveHistory/Index"),
        meta: {
          menu: "reserveHistory",
        },
      },
    ],
  },
  {
    path: "/report",
    name: "Report Page",
    meta: {
      layout: "main",
      menu: "report",
    },
    children: [
      {
        path: "book",
        name: "Laporan Buku",
        component: () => import("@/views/Report/Book"),
        meta: {
          menu: "report-book",
        },
      },
      {
        path: "guest_book",
        name: "Laporan Buku Tamu",
        component: () => import("@/views/Report/GuestBook"),
        meta: {
          menu: "report-guest-book",
        },
      },
      {
        path: "reserve_book",
        name: "Laporan Peminjaman Buku",
        component: () => import("@/views/Report/ReserveBook"),
        meta: {
          menu: "report-reserve-book",
        },
      },
      {
        path: "finance",
        name: "Laporan Keuangan ",
        component: () => import("@/views/Report/Finance"),
        meta: {
          menu: "report-finance",
        },
      },
    ],
  },
  {
    path: "/video",
    name: "Video Page",
    meta: {
      layout: "main",
      menu: "video",
    },
    children: [
      {
        path: "",
        name: "List Video Pembelajaran",
        component: () => import("@/views/Video/Index"),
        meta: {
          menu: "video",
        },
      },
      {
        path: "create",
        name: "Tambah Video Pembelajaran",
        component: () => import("@/views/Video/Create"),
        meta: {},
      },
      {
        path: ":video_id/edit",
        name: "Edit Video Pembelajaran",
        component: () => import("@/views/Video/Edit"),
        meta: {},
      },
      {
        path: "category",
        name: "Kategori Video",
        component: () => import("@/views/Video/Category/Index"),
        meta: {
          menu: "video_category",
        },
      },
      {
        path: "video_channel",
        name: "Instruktur Video",
        meta: {
          menu: "video_channel",
        },
        children: [
          {
            path: "",
            name: "List Instruktur Video",
            component: () => import("@/views/Video/VideoChannel/Index.vue"),
          },
          {
            path: "create",
            name: "Tambah Instruktur Video",
            component: () => import("@/views/Video/VideoChannel/Create.vue"),
          },
          {
            path: ":video_channel_id/edit",
            name: "Edit Video Chennel",
            component: () => import("@/views/Video/VideoChannel/Edit.vue"),
            meta: {},
          },
        ],
      },
      {
        path: ":video_id",
        name: "Tonton Video Pembelajaran",
        component: () => import("@/views/Video/Detail"),
        meta: {},
      },
    ],
  },
  {
    path: "/audio",
    name: "Audio Book Page",
    meta: {
      layout: "main",
      menu: "audio",
    },
    children: [
      {
        path: "",
        name: "List Audio Book",
        component: () => import("@/views/Audio/Index"),
      },
      {
        path: "create",
        name: "Tambah Audio Book",
        component: () => import("@/views/Audio/Create"),
        meta: {},
      },
      {
        path: ":audio_id/edit",
        name: "Edit Audio Book",
        component: () => import("@/views/Audio/Edit"),
        meta: {},
      },
      {
        path: "category",
        name: "Kategori Audio Book",
        component: () => import("@/views/Audio/Category/Index"),
        meta: {
          menu: "audio_category",
        },
      },
      {
        path: "audio_channel",
        name: "Instruktur Audio Book",
        meta: {
          menu: "audio_channel",
        },
        children: [
          {
            path: "",
            name: "List Instruktur Audio Book",
            component: () => import("@/views/Audio/Channel/Index.vue"),
          },
          {
            path: "create",
            name: "Tambah Instruktur Audio Book",
            component: () => import("@/views/Audio/Channel/Create.vue"),
          },
          {
            path: ":audio_channel_id/edit",
            name: "Edit Audio Kategori",
            component: () => import("@/views/Audio/Channel/Edit.vue"),
            meta: {},
          },
        ],
      },
    ],
  },
  {
    path: "/setting",
    name: "Pengaturan Aplikasi",
    component: () => import("@/views/Setting/Index"),
    meta: {
      layout: "main",
      menu: "setting",
    },
  },
  {
    path: "/variable",
    name: "Pengaturan Variable Umum",
    component: () => import("@/views/Variable/Index"),
    meta: {
      layout: "main",
      menu: "variable",
    },
  },
  {
    path: "/guest_book",
    name: "Buku Tamu Pengunjung",
    component: () => import("@/views/GuestBook/Index"),
    meta: {
      layout: "main",
      menu: "guest_book",
    },
  },
  {
    path: "/membership-card/:member_id",
    name: "Kartu Tanda Anggota",
    component: () => import("@/views/MembershipCard/Index"),
    meta: {
      layout: "auth",
      menu: "membership_card",
    },
  },
  {
    path: "/guest_book/scan",
    name: "Buku Tamu Scan",
    component: () => import("@/views/GuestBook/Scan"),
    meta: {
      layout: "scan",
      menu: "scan_guest_book",
    },
  },
  {
    path: "/guest_book/print",
    name: "Buku QR Buku Tamu",
    component: () => import("@/views/GuestBook/Print"),
    meta: {
      layout: "auth",
      menu: "scan_guest_book",
    },
  },
  {
    path: "/member/qr/:username",
    name: "Scan Membership",
    component: () => import("@/views/GuestBook/MemberScan"),
    meta: {
      layout: "auth",
      menu: "scan_guest_book_qr",
    },
  },
  {
    path: "/guest_book/scan/:token",
    name: "Buku Scan Token",
    component: () => import("@/views/GuestBook/ProcessQR"),
    meta: {
      layout: "auth",
      menu: "scan_token",
    },
  },
  {
    path: "/change-password",
    name: "Halaman Ubah Password",
    component: () => import("@/views/Auth/ChangePassword"),
    meta: {
      layout: "main",
      menu: "change_password",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("@/components/404.vue"),
    meta: {
      layout: "auth",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch("CANCEL_PENDING_REQUESTS");
  next();
});

export default router;
